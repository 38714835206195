/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    blockquote: "blockquote",
    ol: "ol",
    li: "li",
    h3: "h3",
    code: "code",
    pre: "pre",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Set up USB drive with single ISO"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://ubuntu.com/tutorials/create-a-usb-stick-on-macos"
  }, "ubuntu walk"), " through work for most linux derivative."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "The process uses Etcher which does not support writing to a partition of a given drive"), "\n"), "\n", React.createElement(_components.h2, null, "Set up USB drive with multiple ISO's"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "partition the drive"), "\n", React.createElement(_components.li, null, "unmount the partition we wish to write to"), "\n", React.createElement(_components.li), "\n"), "\n", React.createElement(_components.p, null, "The most direct solution to this is to use the terminal."), "\n", React.createElement(_components.h3, null, "Example"), "\n", React.createElement(_components.p, null, "Assumes disk is partitioned, formatted and ISO's are downloaded."), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "identity target disk identifier: ", React.createElement(_components.code, null, "diskutil list")), "\n", React.createElement(_components.li, null, "unmount target: ", React.createElement(_components.code, null, "diskutil unmount disk3s8")), "\n", React.createElement(_components.li, null, "flash or burn iso to partition"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-shell"
  }, " sudo dd if=<path-to-iso>/ubuntu-21.04-desktop-amd64.iso of=/dev/rdisk3s9 bs=4m\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "There is no progress bar so just wait it out, how long the ISO copy process takes depends on a variety of things, including the speed of the Mac, the speed of the target volume, and the size of the ISO file being copied or burned to the destination."), "\n"), "\n", React.createElement(_components.h2, null, "Partition usb"), "\n", React.createElement(_components.p, null, "A usb drive can be partition from diskutility"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "navigate to view and \"show all devices\""), "\n"), "\n", React.createElement(_components.h3, null, "Command Reference"), "\n", React.createElement(_components.h4, null, React.createElement(_components.code, null, "diskutil list")), "\n", React.createElement(_components.h4, null, "unmount disk"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "sudo umount /dev/<identifier>")), "\n", React.createElement(_components.p, null, "or"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "diskutil unmount /dev/<identifier>")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "diskutil unmount /dev/disk3s9")), "\n", React.createElement(_components.p, null, "or"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "diskutil unmountDisk /dev/disk3")), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.h4, null, "Articles"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://osxdaily.com/2015/06/05/copy-iso-to-usb-drive-mac-os-x-command/"
  }, "Copy ISO to USB Mac os command line")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.balena.io/blog/did-etcher-break-my-usb-sd-card/"
  }, "Balena - Recover USB drive")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://askubuntu.com/questions/363842/how-to-make-the-dd-command-be-verbose"
  }, "How to make dd command verbose")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.balena.io/etcher/"
  }, "balena Etcher")), "\n", React.createElement(_components.p, null, "Flash OS images to SD cards & USB drives, safely and easily."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.ventoy.net/"
  }, "Ventoy")), "\n", React.createElement(_components.p, null, "A New Bootable USB Solution"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.linuxbabe.com/apps/create-multiboot-usb-linux-windows-iso"
  }, "Multiboot options")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
